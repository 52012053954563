// Navbar
// -----------------------------------------------------
.navbar {
    margin-bottom: 0;
    border: none;
}

.navbar-brand {
    font-size: ($font-size-base + 2);
    span {
        display: inline-block;
        padding-left: 38px;
        font-family: $headings-font-family;
        text-transform: uppercase;
    }
    .section-name {
        padding-left: 0;
    }
    > img {
        position: absolute;
        top: 20px;
    }
}

.navbar-nav {
    li {
        > a {
            padding-left: 15px;
            @media (min-width: $screen-sm-min) {
                padding-left: 15px;
            }
            .fa:before {
                font-size: 20px;
                display: inline-block;
                vertical-align: -10%;
                margin-right: 8px;
            }
            img {
                position: absolute;
                left: 15px;
                top: 8px;
                width: 28px;
                height: auto;
                @include border-radius(200px);
                @media (min-width: $screen-sm-min) {
                    top: 22px;
                }
            }
        }
        >.dropdown-toggle {
            padding-left: 54px;
        }
    }
}

.navbar-collapse {
    border-top: none;
}

@media (max-width: $screen-sm-min) {
    .navbar-inverse {
        .navbar-collapse {
            background: $gray-light;
            border-bottom: 1px solid #ddd;
        }
        .navbar-nav {
            li {
                a {
                    color: $gray-dark;
                    &:hover,
                    &:focus {
                        color: $gray-dark;
                    }
                }
            }
        }
    }
    #navbar-sections {
        .current {
            background: none;
        }
        .actives {
            a {
                color: $gray-dark;
            }
        }
    }
}

.navbar-inverse {
    .navbar-nav {
        > .open > a {
            &,
            &:hover,
            &:focus {
                color: $gray-dark;
            }
        }
        @media (max-width: $grid-float-breakpoint-max) {
            // Dropdowns get custom display
            .open .dropdown-menu {
                > li > a {
                    color: $gray-dark;
                    &:hover,
                    &:focus {
                        color: $gray-dark;
                    }
                }
                > .active > a {
                    &,
                    &:hover,
                    &:focus {
                        color: $gray-dark;
                    }
                }
                > .disabled > a {
                    &,
                    &:hover,
                    &:focus {
                        color: $gray-dark;
                        ;
                    }
                }
            }
        }
    }
}