// Modals
// -----------------------------------------------------
.modal-content {
    border: none;
    @include box-shadow(0 3px 9px rgba(0, 0, 0, 0.1));
}

.modal-backdrop {
    z-index: 1040;
}

.modal-dialog {
    z-index: 1045;
}

.modal-body {
    ul {
        padding: 0;
        margin: {
            top: 0;
            left: 0;
            right: 0;
            bottom: 20px;
        }
        li {
            padding: 15px 0;
            list-style: none;
            border-bottom: 1px solid $line-divider;
            img.avatar {
                width: 34px;
                height: auto;
                float: left;
                border-radius: 200px;
                -webkit-border-radius: 200px;
                -moz-border-radius: 200px;
                -o-border-radius: 200px;
            }
            p {
                color: $gray-base;
                font-weight: 600;
                margin-bottom: 0;
                font-size: 13px;
            }
            span {
                display: block;
                font-size: 12px;
                a {
                    color: $gray-darker;
                }
            }
            p,
            span {
                padding-left: 45px;
            }
            button {
                float: right;
            }
        }
    }
    ul.social {
        margin-top: 30px;
        li {
            padding: 0;
            margin-right: 15px;
            display: inline-block;
            border: none;
            i {
                font-size: 40px;
            }
        }
    }
    .emojify {
        width: 1.8em;
        height: 1.4em;
    }
}