// Progress bars
// -----------------------------------------------------
.content-progres-bar {
    position: relative;
    padding: 13px;
    background: $gray-lighter;
    span {
        position: absolute;
        top: 8px;
        right: 13px;
        font-size: $font-size-small;
    }
}

.progress {
    height: ceil(($line-height-computed / 3.5));
    margin-bottom: 0;
    @include box-shadow(none);
    max-width: 82%;
}

.progress-bar {
    @include box-shadow(none);
}

.progress-bar[aria-valuenow="0"] {
    min-width: 0px;
}

#sidebar {
    .content-progres-bar {
        padding: 25px 25px 15px 25px;
        background: none;
        span {
            top: 19px;
            right: 25px;
        }
    }
    .progress {
        max-width: 78%;
    }
}

.progress-bar[aria-valuenow="1"],
.progress-bar[aria-valuenow="2"] {
    min-width: auto;
}