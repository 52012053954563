// Global
// -----------------------------------------------------

h1,
h2,
h3 {
    text-transform: uppercase;
}

.navbar-akka {
    background: $brand-primary;
}

section {
    padding: {
        top: ($padding-large-vertical * 2);
        bottom: ($padding-large-vertical * 3);
    }
    @media (min-width: $screen-sm-min) {
        padding: {
            top: ($padding-large-vertical * 7);
            bottom: ($padding-large-vertical * 8);
        }
    }
    h2 {
        margin-bottom: $line-height-computed;
    }
}

// Home
.section-name {
    // TODO: Isra, fill me!	      
}

.section-description {
    margin-bottom: ($line-height-computed * 2);
    font-size: $font-size-large;
    @media (min-width: $screen-sm-min) {
        margin-bottom: ($line-height-computed * 3);
    }
}

// Gitter embed chat
.gitter-chat-embed {
  top: $navbar-height;
}
