//
// Code (inline and block)
// --------------------------------------------------
// Line
code {
    color: $gray-dark;
    background-color: $pre-bg;
    font-family: monospace, monospace;
}

// Blocks of code
pre {
    display: block;
    font-family: monospace, monospace;
    padding: 28px 20px;
    font-size: 14px;
    margin: {
        top: ($line-height-computed);
        bottom: ($line-height-computed);
        left: 0;
        right: 0;
    }
    border: none;
    border-radius: 0;
    .hljs {
        background-color: $pre-bg;
    }
    
    
    code.exercise-code {
        input {
            background-color: $pre-bg;
            font-size: 14px;
            font-family: monospace, monospace;
            text-align: left;
            line-height: 11px;
            vertical-align: middle;
            padding: 2px 0px;
            margin-left: 4px;
            margin-right: 4px;
            border: 0px solid #999;
            width: 12px;
        }
        input:hover,
        input:focus,
        input:active {
            outline: none;
        }
    }
}

pre.exercise-pre {
    margin-bottom: 0;
}