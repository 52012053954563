// Exercises
// -----------------------------------------------------
.content-header {
    padding: 20px 0;
    h2 {
        margin: 0;
    }
}

.exercises-wrapper {
    position: relative;
    padding: 30px;
    background: #fff;
    line-height: 1.6;
    h4 {
        font-size: 16px;
        font-weight: bold;
        margin-top: 20px;
    }
    p {
        font-size: floor(($font-size-base * 1.15));
    }
    .hljs {
        background: $gray-lighter;
        padding: 30px;
        box-shadow: inset 3px 0 rgba(0,0,0, 0.1);
    }

    .solved-exercise {
        border: 2px solid #9CCC65;
        box-shadow: none;
    }
    .exercise-pre {
        min-height: 60px;
    }
    .exercise {
        margin-bottom: 15px;
        .highlight {
            position: relative;
            .compile {
                position: absolute;
                right: 8px;
                bottom: 8px;
                button {
                    transition: opacity 0.5s linear;
                }

                i {
                    margin-right: 4px;
                    font-size: 10px;
                }

                button:hover,
                button:focus,
                button:active {
                    outline: none;
                }
            }
        }
        input {
            color: $input-unsolved;
            border-bottom: 1px solid $input-unsolved;
            animation: underliner_unsolved 2s steps(1) infinite;
            transform: translateZ(0);
            will-change: transform, border-bottom;
        }
        ul.list-sources {
            padding: 0;
            margin: 0;
            li {
                list-style: none;
                margin-bottom: 40px;
                &:last-child {
                    margin-bottom: 0;
                }
                a {
                    &:hover,
                    &:focus,
                    &:active {
                        text-decoration: none;
                    }
                }
            }
        }
        .highlight:last-child pre {
            margin-bottom: 0;
        }
        .bullet-state {
            width: 28px;
            height: 28px;
            position: absolute;
            right: 8px;
            top: 8px;
            background-color: none;
        }
    }
    .log {
        background-color: #E57373;
        color: $gray-light;
        font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;
        font-size: 12px;
        line-height: 30px;
        vertical-align: middle;
        padding: 0px 12px;
        margin-top: 0px;
        margin-bottom: ($line-height-computed);
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s linear;
    }
}

.exercise.unsolved,
.exercise.errored,
.exercise.solved {
    input {
        background: #fff;
    }
}



.exercise.unsolved {
    .compile {
        button {
            opacity: 0.6;
        }
        .btn {
            &:hover,
            &:focus,
            &:active {
                opacity: 0.6;
                cursor: not-allowed;
                background: #fff;
                border-color: $btn-default-border;
                box-shadow: none;
            }
        }
    }
    input {
        color: $input-unsolved;
        border-bottom: 1px solid $input-unsolved;
        animation: underliner_unsolved 2s steps(1) infinite;
        transform: translateZ(0);
        will-change: transform, border-bottom;
    }
    .bullet-state {
        background: none;
    }
    .log {
        max-height: 0;
    }
}

.exercise.filled {
    .compile {
        button {
            opacity: 1;
        }
    }
    .bullet-state {
        background: none;
    }
    .log {
        max-height: 0;
    }
}

.exercise.evaluating {
    .compile {
        button {
            opacity: 1;
            background: -webkit-linear-gradient(-45deg, rgba(255, 255, 255, 0.6) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.6) 50%, rgba(255, 255, 255, 0.6) 75%, transparent 75%, transparent), -webkit-linear-gradient(top, #f6f5f0, #e1e0dc);
            background: -moz-linear-gradient(-45deg, rgba(255, 255, 255, 0.6) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.6) 50%, rgba(255, 255, 255, 0.6) 75%, transparent 75%, transparent), -moz-linear-gradient(top, #f6f5f0, #e1e0dc);
            background: -ms-linear-gradient(-45deg, rgba(255, 255, 255, 0.6) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.6) 50%, rgba(255, 255, 255, 0.6) 75%, transparent 75%, transparent), -ms-linear-gradient(top, #f6f5f0, #e1e0dc);
            background: -o-linear-gradient(-45deg, rgba(255, 255, 255, 0.6) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.6) 50%, rgba(255, 255, 255, 0.6) 75%, transparent 75%, transparent), -o-linear-gradient(top, #f6f5f0, #e1e0dc);
            background: linear-gradient(-45deg, rgba(255, 255, 255, 0.6) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.6) 50%, rgba(255, 255, 255, 0.6) 75%, transparent 75%, transparent), linear-gradient(top, #f6f5f0, #e1e0dc);
            -pie-background: linear-gradient(-45deg, rgba(255, 255, 255, 0.6) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.6) 50%, rgba(255, 255, 255, 0.6) 75%, transparent 75%, transparent), linear-gradient(top, #f6f5f0, #e1e0dc);
            background-repeat: repeat;
            -webkit-background-size: 40px 40px, 100% 100%;
            -moz-background-size: 40px 40px, 100% 100%;
            -ms-background-size: 40px 40px, 100% 100%;
            -o-background-size: 40px 40px, 100% 100%;
            background-size: 40px 40px, 100% 100%;
            -webkit-animation: progress-bar-stripes 2s linear infinite;
            -moz-animation: progress-bar-stripes 2s linear infinite;
            -ms-animation: progress-bar-stripes 2s linear infinite;
            -o-animation: progress-bar-stripes 2s linear infinite;
            animation: progress-bar-stripes 2s linear infinite;
        }
        .btn {
            &:hover,
            &:focus,
            &:active {
                border-color: $btn-default-border;
                box-shadow: none;
                cursor: not-allowed;
            }
        }
    }
    .bullet-state {
        background: url("../images/icon_evaluating.svg") no-repeat;
        -webkit-animation: spin 1s infinite linear;
    }

    @-webkit-keyframes spin {
        0%  {-webkit-transform: rotate(0deg);}
        100% {-webkit-transform: rotate(360deg);}
    }

    .log {
        max-height: 0;
    }
}

.exercise.errored {
    .compile {
        button {
            opacity: 1;
        }
    }
    input {
        color: $input-errored;
        border-bottom: 1px solid $input-errored;
        animation: underliner_errored 2s steps(1) infinite;
        transform: translateZ(0);
        will-change: transform, border-bottom;
    }
    .bullet-state {
        background: url("../images/icon_errored.svg") no-repeat;
    }
    .log {
        max-height: unset;
    }
}

.exercise.solved {
    .compile {
        button {
            display: none;
        }
    }
    input {
        color: $input-solved;
        border-bottom: none;
        animation: none;
    }
    .bullet-state {
        background: url("../images/icon_solved.svg") no-repeat;
    }

    .log {
        max-height: 0;
    }
}

.contributors {
    border-top: 1px solid #EAEAEA;
    background: #fff;
    @include box-shadow(0 1px 1px rgba(0, 0, 0, .05));
    ul {
        display: block;
        padding: {
            top: 15px;
            bottom: 15px;
            left: 30px;
            right: 0;
        }
        @media (min-width: $screen-sm-min) {
            float: left;
        }
        margin: 0;
        li {
            list-style: none;
            display: inline-block;
            margin-right: 3px;
            font-size: $font-size-base;
            &:first-child {
                margin-right: 15px;
            }
            a {
                color: $gray-dark;
                i {
                    margin-right: 10px;
                }
                &:hover,
                &:focus,
                &:active {
                    text-decoration: none;
                    color: $gray-darker;
                }
            }
            img {
                width: 24px;
                height: auto;
                @include border-radius(200px);
            }
        }
        &:last-child {
            border-top: 1px solid $line-divider;
            padding: {
                top: 17px;
                bottom: 18px;
                left: 30px;
            }
            @media (min-width: $screen-sm-min) {
                float: right;
                border-top: none;
                border-left: 1px solid $line-divider;
                padding-right: 30px;
            }
        }
    }
}

.comments {
    margin-top: 30px;
}
