// Footer
// -----------------------------------------------------
#bottom {
    padding: {
        top: ($padding-large-vertical * 2);
        bottom: ($padding-large-vertical * 3);
    }
    @media (min-width: $screen-sm-min) {
        padding: {
            top: ($padding-large-vertical * 4);
            bottom: ($padding-large-vertical * 5);
        }
    }
    img {
        float: left;
    }
    p {
        span {
            margin-left: 10px;
        }
    }
    ul {
        padding: 0;
        li {
            padding-right: 3px;
            list-style: none;
            display: inline-block;
        }
    }
}

.dark {
    background: $gray-base;
    a {
        .fa:before {
            font-size: 20px;
            display: inline-block;
            vertical-align: -10%;
            margin-right: 8px;
        }
        text-decoration: underline;
        color: $dark-link-color;
        &:hover {
            text-decoration: none;
            color: $dark-link-color-hover;
        }
    }
}

.light {
    background: $gray-lighter;
    color: $gray-dark;
    a {
        text-decoration: underline;
        color: $light-link-color;
        &:hover {
            text-decoration: none;
            color: $light-link-color-hover;
        }
    }
}